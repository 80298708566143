async function showStatusBar() {
  const { Capacitor } = await import('@capacitor/core')
  const { StatusBar } = await import('@capacitor/status-bar')
  const { SplashScreen } = await import('@capacitor/splash-screen')

  await SplashScreen.hide()
  if (Capacitor.getPlatform() !== 'web') await StatusBar.show()
}

export default { showStatusBar }
